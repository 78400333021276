<script setup>
import { useRouter } from '@/utils/vue_helpers';
import { onMounted, ref } from 'vue'

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    links: {
        type: Array,
        default: null
    }
})

const currentRouteName = ref(null)

const { route } = useRouter()

function isCurrentlySelected(link) {
    const routeServiceName = route.value?.query['service-name']
    const linkServiceName = link.route.query['service-name']
    return routeServiceName === linkServiceName
}

onMounted(() => {
    currentRouteName.value = route.value.name
})
</script>

<template>
    <v-list class="background pt-0" :style="{ height: '100%', minHeight: '70vh' }">
        <v-list-item class="primary">
            <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-h6 white--text">
                    {{ title }}
                </v-list-item-title>
            </v-list-item-content>
            <!-- <v-subheader>Environment</v-subheader> -->
        </v-list-item>

        <v-divider />

        <v-list-item-group v-model="currentRouteName">
            <router-link v-for="(link) in links" :key="link.text" :to="link.route" class="text-decoration-none">
                <div class="d-flex">
                    <div v-if="isCurrentlySelected(link)" class="primary" :style="{ width: '6px' }">
                    </div>
                    <v-list-item :class="{
                        'white--text white': isCurrentlySelected(link)
                    }">
                        <v-list-item-content :class="{
                            'py-4': true,
                        }">
                            <v-list-item-title class="black--text text-body-2">
                                {{ link.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                <v-divider />
            </router-link>
        </v-list-item-group>
    </v-list>
</template>