<script setup>
import { computed, ref, watchEffect } from 'vue';
import { links } from './links';
import ServiceContainerMenu from './ServiceContainerMenu.vue';
import { useRouter } from '@/utils/vue_helpers';

const { route, routeNotEmpty } = useRouter()

const currentServiceCategory = ref(null)

watchEffect(() => {
    if (routeNotEmpty.value) {
        const { serviceCategory } = route.value.query
        console.log(serviceCategory)
        if (!!serviceCategory) {
            currentServiceCategory.value = serviceCategory
        }
    }
})

const currentSection = computed(() => {
    let current = {}
    const currentServiceCategoryNotEmpty = !!currentServiceCategory
    if (currentServiceCategoryNotEmpty) {
        current = links[currentServiceCategory.value]
    }
    return current
})

const currentSectionNotEmpty = computed(() => {
    return !!currentSection.value
})
</script>

<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-container>
                        <v-row>
                            <v-col cols="3" class="px-0 py-0">
                                <ServiceContainerMenu v-if="currentSectionNotEmpty" :title="currentSection.title" :links="currentSection.links" />
                            </v-col>
                            <v-col cols="9">
                                <router-view />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>